<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pa-0">
      <v-stepper v-model="stepper" flat color="success">
        <v-stepper-header>
          <v-stepper-step
              color="success"
              :complete="formValid1"
              step="1"
          >
            Основне
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
              color="success"
              :complete="formValid2"
              step="2"
          >
            Адреса
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
              color="success"
              :complete="formStep3"
              step="3"
          >
           Договір
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="pb-1">
              <v-form v-model="formStep1" ref="form_step_1">
                <v-row>
                  <v-col cols="12" md="8" sm="7">
                    <v-text-field type="text"
                                  filled
                                  label="Повна назва"
                                  v-model="full_name"
                                  hide-details
                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                  :class="full_name ? '' : 'req-star'"
                                  color="grey"/>
                  </v-col>
                  <v-col cols="12" md="4" sm="5">
                    <v-checkbox color="success" label="Фізична особа" hide-details class="mt-2" v-model="is_legal_person"/>
                  </v-col>
                  <v-col cols="12" md="8" sm="7">
                    <v-text-field type="text"
                                  filled
                                  label="Коротка назва"
                                  v-model="short_name"
                                  hide-details
                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                  :class="short_name ? '' : 'req-star'"
                                  color="grey"/>
                  </v-col>
                  <v-col cols="12" md="4" sm="5">
                    <v-text-field type="text"
                                  filled
                                  label="Особовий рахунок"
                                  v-model="person_id"
                                  hide-details
                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                  :class="person_id ? '' : 'req-star'"
                                  color="grey"/>
                  </v-col>
                  <v-col cols="6" md="4" sm="6">
                    <v-text-field type="text"
                                  filled
                                  label="Код ЄДРПОУ"
                                  v-model="code"
                                  hide-details
                                  v-if="!is_legal_person"
                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                  :class="code ? '' : 'req-star'"
                                  color="grey"/>
                    <v-text-field type="text"
                                  filled
                                  label="Код ДРФО"
                                  v-model="ident_code"
                                  hide-details
                                  v-if="is_legal_person"
                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                  :class="ident_code ? '' : 'req-star'"
                                  color="grey"/>
                  </v-col>
                  <v-col cols="6" md="4" sm="6">
                    <v-select type="text" filled label="Ставка ПДВ" v-model="tax_type"
                              hide-details
                              :items="tax_select"
                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                              :class="tax_type ? '' : 'req-star'"
                              color="grey"/>
                  </v-col>
                  <v-col cols="6" md="4" sm="6">
                    <v-text-field type="text"
                                  filled
                                  label="Номер свідоцтва ПДВ"
                                  v-model="ipn_tax"
                                  hide-details
                                  color="grey"/>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field type="text"
                                  filled
                                  label="ЕІС код"
                                  v-model="eis_code"
                                  hide-details
                                  color="grey"/>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <ACC_ChartOfAccount
                        :value="chart_of_account_id"
                        color="grey"
                        label="Рахунок розрахунів (для юр. обліку)"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div class="pb-1">
              <v-form v-model="formStep2" ref="form_step_2">
                <v-row>
                  <v-col cols="12">
                    <AddressElementSelect v-model="city_id" req
                                          label="Населений пункт" filled
                                          select_type="city"
                                          :auto_fill_when_first="true"
                    />
                  </v-col>
                  <v-col cols="12">
                    <AddressElementSelect :parent_id="city_id" v-model="street_id"
                                          label="Вулиця(-ки)" filled req
                                          select_type="street" use_parent_id
                                          :auto_fill_when_first="true"
                                          :disabled="!city_id"
                                          @selectChanged="streetChange"
                    />
                  </v-col>
                  <v-col cols="6" md="8" style="display: flex">
                    <AddressElementSelect :parent_id="street_id" v-model="building_id"
                                          label="Будинок(-ки)" filled req
                                          select_type="building" use_parent_id
                                          :disabled="!street_id"
                                          :auto_fill_when_first="true"
                                          style="flex: 1"
                                          @selectChanged="buildingChange"
                    />
                    <MainModal
                        :main="{ component: 'Building', title: 'Будинки' }"
                        :button="{ icon: false, color: 'secondary', buttonIcon: 'mdi-plus', buttonDepressed: true, buttonClass: 'ml-2 grey lighten-3', buttonStyle: 'height: 100%' }"
                    />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field type="text"
                                  filled
                                  label="Номер приміщення"
                                  v-model="flat_number"
                                  hide-details
                                  color="grey"/>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="legal_address" label="Юридична адреса" color="grey" filled hide-details auto-grow
                                rows="1"/>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="fact_address" label="Фактична адреса" color="grey" filled hide-details auto-grow
                                rows="1"/>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div class="pb-1">
              <v-form v-model="formStep3" ref="form_step_3">
                <v-row>
                  <v-col cols="6" md="4" sm="6">
                    <date-component v-model="contract_date" :class_="contract_date ? '' : 'req-star'" req
                                    label="Дата договору"/>
                  </v-col>
                  <v-col cols="6" md="4" sm="6">
                    <v-text-field type="text"
                                  filled
                                  label="Назва договору"
                                  v-model="contract_name"
                                  hide-details
                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                  :class="contract_name ? '' : 'req-star'"
                                  color="grey"/>
                  </v-col>
                  <v-col cols="6" md="4" sm="6" class="pb-0">
                    <v-text-field type="text"
                                  filled
                                  label="Номер договору"
                                  v-model="contract_number"
                                  hide-details
                                  color="grey"/>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-select type="text" filled label="Посуга" v-model="service_id"
                              hide-details
                              :items="services"
                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                              :class="service_id ? '' : 'req-star'"
                              color="grey"/>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                        :label="contract_tax_by_pay ? 'ПДВ по оплаті (включено)' : 'ПДВ по оплаті (відключено)'"
                        v-model="contract_tax_by_pay" color="success" class="mt-2" hide-details
                    />
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          depressed text
          color="success"
          v-show="stepper > 1"
          @click="doStepperValidationLeft"
      >
        Попередня
      </v-btn>
      <v-btn
          depressed text
          color="success"
          v-show="stepper < 3"
          @click="doStepperValidationRight"
      >
        Наступна
      </v-btn>
      <v-btn depressed text color="secondary darken-1" @click="crud_item"
             :loading="getModalLoading"
             v-if="formValid1 && formValid2 && formValid3"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_LEGACY_FLAT
} from "@/store/actions/flat";
import {mapActions, mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {SELECT_TAXES_LIST_ELEMENTS} from "@/store/actions/accounting/list";
import {beginOfMonth, getCurrentDate} from "@/utils/icons"
import flatAPI from "@/utils/axios/flat";

export default {
  name: "HWL_Modal_FlatContractorCreate",
  props: ['title', 'item', 'dialog'],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    MainModal: () => import("@/components/modal/MainModal"),
    ACC_ChartOfAccount: () => import("@/components/accounting/autocomplite/modal/ACC_Chart_of_account")
  },
  data() {
    return {
      formValid: false,
      formStep1: false,
      formStep2: false,
      formStep3: false,
      stepper: 1,
      watcher: null,
      dialog_watcher: null,
      full_name: null,
      short_name: null,
      is_legal_person: true,
      ident_code: null,
      ipn_tax: null,
      tax_type: null,
      fact_address: null,
      legal_address: null,
      code: null,
      person_id: null,
      city_id: null,
      street_id: null,
      building_id: null,
      flat_number: null,
      contract_date: null,
      contract_name: null,
      contract_number: null,
      contract_tax_by_pay: false,
      service_id: null,
      chart_of_account_id: null,
      menu: false,
      eis_code: null
    }
  },
  watch: {
    dialog(payload) {
      if (payload) {
        if (this.general_settings && this.dialog) {
          if (this.general_settings.person_id_auto) {
            if (this.general_settings.person_id_auto.value === true) {
              const payload = {
                person_hash: '',
                person_id: this.person_id ? this.person_id : '',
              }

              flatAPI.get_legacy_max_person_id(payload)
                  .then(response => response.data)
                  .then(data => {
                    this.person_id = data
                  })
                  .catch(err => {
                    const error = err.response.data.detail;
                    this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                  })
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTaxSelect: SELECT_TAXES_LIST_ELEMENTS
    }),
    doStepperValidationRight() {
      if (this.stepper === 1) {
        this.$refs.form_step_1.validate()
        if (this.formStep1 && this.formValid1) {
          this.stepper = 2
            return;
        }
      }
      if (this.stepper === 2) {
        this.$refs.form_step_2.validate()
        if (this.formStep2 && this.formValid2) {
          this.stepper = 3
            return;
        }
      }
      if (this.stepper === 3) {
        this.$refs.form_step_3.validate()
      }
    },
    doStepperValidationLeft() {
      if (this.stepper <= 1) {
        return
      }
      this.stepper -= 1
    },
    streetChange(payload) {
      const text = (payload || {}).text || ''

      this.$nextTick(() => {
        this.legal_address = text
        this.fact_address = text
      })
    },
    buildingChange(payload) {
      const text = (payload || {}).text || ''

      this.$nextTick(() => {
        this.legal_address = text
        this.fact_address = text
      })
    },
    closeModal() {
      this.$emit('closeModal')
      this.full_name = null
      this.short_name = null
      this.is_legal_person = true
      this.ident_code = null
      this.ipn_tax = null
      this.tax_type = null
      this.fact_address = null
      this.legal_address = null
      this.code = null
      this.person_id = null
      this.city_id = null
      this.street_id = null
      this.building_id = null
      this.flat_number = null
      this.contract_date = beginOfMonth(getCurrentDate())
      this.contract_name = 'Основний'
      this.contract_number = null
      this.contract_tax_by_pay = false
      this.service_id = null
      this.chart_of_account_id = null
      this.stepper = 1
      this.eis_code = null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    crud_item() {
      this.$refs.form_step_1.validate()
      this.$refs.form_step_2.validate()
      this.$refs.form_step_3.validate()

      if (!this.formValid1 || !this.formValid2 || !this.formValid3) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі поля, які відмічені червоним - обов’язкові для заповнення',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        full_name: this.full_name,
        short_name: this.short_name,
        is_legal_person: this.is_legal_person,
        ident_code: this.ident_code,
        ipn_tax: this.ipn_tax,
        tax_type: this.tax_type,
        fact_address: this.fact_address,
        legal_address: this.legal_address,
        code: this.code,
        person_id: this.person_id,
        city_id: this.city_id,
        street_id: this.street_id,
        building_id: this.building_id,
        flat_number: this.flat_number,
        contract_date: this.contract_date,
        contract_name: this.contract_name,
        contract_number: this.contract_number,
        tax_by_pay: this.contract_tax_by_pay,
        service_id: this.service_id,
        chart_of_account_id: this.chart_of_account_id,
        eis_code: this.eis_code
      }

      flatAPI.check_legacy_person_id_id({person_id: this.person_id, person_hash: ''})
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.$store.dispatch(CREATE_LEGACY_FLAT, payload)
                  .then(el => {
                    if (el) {
                      this.closeModal()
                    }
                  })
            } else {
              this.$store.dispatch(ALERT_SHOW, {
                text: 'Абонент із таким особовим рахунком вже існує',
                color: 'error lighten-1'
              })
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })


    },
  },
  computed: {
    ...mapGetters({
      modalAnswer: 'question_answer',
      getModalLoading: 'get_modal_loading',
      tax_select: 'get_taxes_list_pdv_select',
      general_settings: 'getGeneralSettings',
      services: 'getServicesSelectWithoutAll',
    }),
    formValid1() {
      if (!this.full_name || !this.short_name) {
        return false
      }
      if (this.is_legal_person && !this.ident_code) {
        return false
      }
      if (!this.is_legal_person && !this.code) {
        return false
      }
      return !!(this.tax_type);

    },
    formValid2() {
      return !!(this.building_id)
    },
    formValid3() {
      return !(!this.contract_name || !this.service_id);
    }
  },
  created() {
    this.contract_name = 'Основний'
    this.contract_date = beginOfMonth(getCurrentDate())
    this.fetchTaxSelect()
        .then(response => response.data)
        .then(() => {
          if (this.general_settings && this.dialog) {
            if (this.general_settings.person_id_auto) {
              if (this.general_settings.person_id_auto.value === true) {
                const payload = {
                  person_hash: '',
                  person_id: this.person_id ? this.person_id : '',
                }

                flatAPI.get_legacy_max_person_id(payload)
                    .then(response => response.data)
                    .then(data => {
                      this.person_id = data
                    })
                    .catch(err => {
                      const error = err.response.data.detail;
                      this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    })
              }
            }
          }
        })
  }
}
</script>

<style scoped>

</style>